/*.yellow-button {*/
/*    background-image: radial-gradient(circle at center, #ffffff4d, #ffffff33 100%);*/
/*    background-position: 50% 50%;*/
/*    background-repeat: no-repeat;*/
/*    background-size: 0 100%;*/
/*    transition: background-size 0.3s;*/
/*}*/

/*.yellow-button:hover {*/
/*    background-size: 200% 200%;*/
/*}*/

/* Бледно-желтая */
.yellow-button {
    position: relative;
    overflow: hidden;
    background-color: var(--basic-brand);
    border: none;
    color: #1e1e1e;
    z-index: 1;
}

.yellow-button::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    transition:
        opacity 0.3s ease-in-out,
        width 0.5s,
        height 0.5s;
    pointer-events: none;
    z-index: -1;
}

.yellow-button:hover::after,
.yellow-button:focus::after {
    width: 650px;
    height: 650px;
}

/* Бледно-серая */
.grey-button {
    position: relative;
    overflow: hidden;
    background-color: #eff1f7;
    border: none;
    padding: 10px 20px;
    color: #1e1e1e;
    z-index: 1;
}

.grey-button:hover {
    background-color: #ffd73766;
    color: #000000;
}

.grey-button::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    background-color: #ffd737;
    border-radius: 50%;
    transition:
        opacity 0.3s ease-in-out,
        width 0.3s,
        height 0.3s;
    pointer-events: none;
    z-index: -1;
}

.grey-button:hover::after,
.grey-button:focus::after {
    width: 610px;
    height: 610px;
}

/* Баннеры на гравной */
.opacity-button {
    position: relative;
    overflow: hidden;
    transition:
        color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
}

.opacity-button:hover {
    background-color: #ffd73766;
    color: #000000;
}

.opacity-button::after {
    position: absolute;
    top: 0;
    width: 100%;
    height: 200px;
    z-index: -1;
    content: '';
    border-radius: 50%;
    transform: translate(0, -75px) scale(0.1);
    opacity: 0;
    transition:
        transform 0.3s,
        opacity 0.3s;
    background-color: #ffd737;
}

.opacity-button:hover::after {
    opacity: 1;
    transform: translate(0, -75px);
}
