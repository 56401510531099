.yellow-button {
  background-color: var(--basic-brand);
  color: #1e1e1e;
  z-index: 1;
  border: none;
  position: relative;
  overflow: hidden;
}

.yellow-button:after {
  content: "";
  pointer-events: none;
  z-index: -1;
  background-color: #fff3;
  border-radius: 50%;
  width: 0;
  height: 0;
  transition: opacity .3s ease-in-out, width .5s, height .5s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.yellow-button:hover:after, .yellow-button:focus:after {
  width: 650px;
  height: 650px;
}

.grey-button {
  color: #1e1e1e;
  z-index: 1;
  background-color: #eff1f7;
  border: none;
  padding: 10px 20px;
  position: relative;
  overflow: hidden;
}

.grey-button:hover {
  color: #000;
  background-color: #ffd73766;
}

.grey-button:after {
  content: "";
  pointer-events: none;
  z-index: -1;
  background-color: #ffd737;
  border-radius: 50%;
  width: 0;
  height: 0;
  transition: opacity .3s ease-in-out, width .3s, height .3s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.grey-button:hover:after, .grey-button:focus:after {
  width: 610px;
  height: 610px;
}

.opacity-button {
  transition: color .3s ease-in-out, background-color .3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.opacity-button:hover {
  color: #000;
  background-color: #ffd73766;
}

.opacity-button:after {
  z-index: -1;
  content: "";
  opacity: 0;
  background-color: #ffd737;
  border-radius: 50%;
  width: 100%;
  height: 200px;
  transition: transform .3s, opacity .3s;
  position: absolute;
  top: 0;
  transform: translate(0, -75px)scale(.1);
}

.opacity-button:hover:after {
  opacity: 1;
  transform: translate(0, -75px);
}
/*# sourceMappingURL=index.76b840e4.css.map */
